import { apiPost } from '../api/api';
import { Urls, baseUrl } from '../api/urls';

export const recordVisitorsActivity = async () => {
  try {
    // Step 1: Fetch IP address (you can replace this with your preferred method)
    const ipAddress = await fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => data.ip);

    // Step 2: Fetch Geolocation data using the IP address (example using an IP geolocation API)
    const geoResponse = await fetch(`https://ipapi.co/${ipAddress}/json/`);
    const geoData = await geoResponse.json();

    const { country, region, latitude, longitude } = geoData;

    // Step 3: Prepare data to send to your API endpoint
    const data = {
      ip: ipAddress,
      country,
      state: region,
      latitude,
      longitude,
      // Other data you want to record
    };

    // Step 4: Send data to your API
    try {
      return await apiPost(baseUrl + Urls.addAVisitor, data);
    }

    catch (error) {
      console.error('Error recording visitor activity:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error recording visitor activity:', error);
    throw error;
  }
};
