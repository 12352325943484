import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { clientInfo } from '../utils/companyData';
import { keyframes } from '@mui/system';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import themeColors from '../utils/themeColors';

/**
 * Renders an instant call component that appears at the bottom left of the screen.
 *
 * @param {Object} props - The props object containing the children and window properties.
 * @param {ReactNode} props.children - The content to be displayed inside the component.
 * @return {JSX.Element} The rendered instant call component.
 */
function InstantWhatsApp(props) {
  const { whatsappAction } = clientInfo;
  const { children } = props;

  return (
    <Box
      onClick={whatsappAction}
      role="presentation"
      sx={{
        position: 'fixed',
        bottom: 70,
        right: 16,
        cursor: 'pointer',
        zIndex: 1300
      }}
    >
      {children}
    </Box>
  );
}

/**
 * A React component that renders a "Back to Top" button with a scaling effect.
 *
 * @param {object} props - The properties passed to the component.
 * @return {JSX.Element} A JSX element representing the "Back to Top" button.
 */
export default function BackToTop(props) {
  // Define the scaling animation using keyframes
  const scaleAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  `;

  return (
    <React.Fragment>
      <InstantWhatsApp {...props}>
        <Fab size="large" aria-label="scroll back to top" sx={{ backgroundColor: `${themeColors.whatsapp}`, '&:hover': { backgroundColor: `${themeColors.whatsappDark}` } }}>
          <WhatsAppIcon
            sx={{
              color: 'white',
              fontSize: '2rem', // Increase the font size
              animation: `${scaleAnimation} 1.5s infinite`, // Apply the new animation
            }}
          />
        </Fab>
      </InstantWhatsApp>
    </React.Fragment>
  );
}

InstantWhatsApp.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
