import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { clientInfo } from '../utils/companyData';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { keyframes } from '@mui/system'; // Import keyframes from MUI

/**
 * Renders an instant call component that is always visible at the bottom right of the screen.
 *
 * @param {Object} props - The props object containing the children property.
 * @param {ReactNode} props.children - The content to be displayed inside the component.
 * @return {JSX.Element} The rendered instant call component.
 */
function InstantCall(props) {
    const { phoneAction } = clientInfo;
    const { children } = props;

    return (
        <Box onClick={phoneAction} role="presentation" sx={{
            position: 'fixed',
            bottom: 140,
            right: 16,
            cursor: 'pointer',
            zIndex: 1300
        }}
        >
            {children}
        </Box>
    );
}

/**
 * A React component that renders an "Instant Call" button with a ringing phone effect.
 *
 * @return {JSX.Element} A JSX element representing the "Instant Call" button.
 */
export default function InstantCallButton() {
    // Define the ringing animation using keyframes
    const ringAnimation = keyframes`
    0% { transform: rotate(0deg); }
    10% { transform: rotate(15deg); }
    20% { transform: rotate(-10deg); }
    30% { transform: rotate(15deg); }
    40% { transform: rotate(-10deg); }
    50% { transform: rotate(10deg); }
    60% { transform: rotate(-5deg); }
    70% { transform: rotate(5deg); }
    80% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
  `;

    return (
        <React.Fragment>
            <InstantCall>
                <Fab size="large" aria-label="instant call" sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: '#8B0000' } }}>
                    <PhoneInTalkIcon sx={{
                        color: 'white',
                        fontSize: '2rem',
                        animation: `${ringAnimation} 1.5s infinite`,
                    }}
                    />
                </Fab>
            </InstantCall>
        </React.Fragment>
    );
}

// Remove InstantCall.propTypes as it's no longer needed
