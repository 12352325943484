import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import TaxiIcon from '@mui/icons-material/LocalTaxi';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BusinessIcon from '@mui/icons-material/Business';
import CarIcon from '@mui/icons-material/DirectionsCar';

const Services = () => {
  const services = [
    {
      icon: <TaxiIcon fontSize="large" sx={{ color: 'white' }} />,
      title: 'Servicii de Taxi în Botoșani',
      description: [
        'Transport de persoane în regim taxi.',
        'Soluții eficiente de transport urban.',
        'Rezervări rapide și convenabile de taxiuri.'
      ]
    },
    {
      icon: <CalendarTodayIcon fontSize="large" sx={{ color: 'white' }} />,
      title: 'Preluarea Comenzilor Anticipate',
      description: [
        'Serviciu dedicat rezervărilor prealabile de taxi.',
        'Rezervări avansate pentru servicii de taxi.',
        'Planificare flexibilă și adaptată nevoilor clienților.'
      ]
    },
    {
      icon: <BusinessIcon fontSize="large" sx={{ color: 'white' }} />,
      title: 'Firma de Transport Persoane Botoșani Taxi',
      description: [
        'Expertiza locală în transportul de persoane.',
        'Transport de persoane atât pe plan local, cât și internațional.'
      ]
    },
    {
      icon: <CarIcon fontSize="large" sx={{ color: 'white' }} />,
      title: 'Servicii de Transport Intern de Persoane în Regim Taxi',
      description: [
        'Transport rutier de persoane pe distanțe scurte.',
        'Servicii de transport rutier adaptate nevoilor clienților.',
      ]
    }
  ];

  return (
    <Box sx={{ padding: '2rem', textAlign: 'center', backgroundColor: '#1b1b1b', minHeight: '55vh' }}>
      <Typography sx={{ paddingBottom: '20px', paddingTop: '20px', color: 'white' }} variant="h5" fontWeight="bold">
        Ce servicii oferim noi?
      </Typography>
      <Typography variant="h4" sx={{ mb: 4, color: 'white' }}>
        Botoșani  <span style={{ color: '#FFB400' }}>Taxi</span>
      </Typography>
      <Grid container justifyContent="center" padding={1}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} sx={{
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
              backgroundColor: '#333',
              borderRadius: '10px',
              boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
            },
          }}>
            <Box sx={{
              textAlign: 'center',
              padding: '20px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',  // Ensure content is centered vertically
              alignItems: 'center',  // Ensure content is centered horizontally
            }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 80,
                  height: 80,
                  borderRadius: '50%',
                  backgroundColor: '#FFB400',
                  margin: '0 auto 1rem',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    backgroundColor: '#FFA000',
                    transform: 'scale(1.15)',
                  },
                }}
              >
                {service.icon}
              </Box>
              <Typography variant="h6" sx={{ marginBottom: '1rem', color: 'white' }}>{service.title}</Typography>
              {service.description.map((paragraph, index) => (
                <Typography key={index} variant="body2" color="textSecondary" sx={{ marginBottom: '0.5rem', color: 'white' }}>
                  {paragraph}
                </Typography>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
