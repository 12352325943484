import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { clientInfo } from "../utils/companyData";
import Map from "./Map";
import themeColors from "../utils/themeColors";
const ContactInfo = () => {

    const { phone, email1, phoneAction, whatsappAction } = clientInfo;
    const needATaxi = process.env.PUBLIC_URL + '/images/movingCar.png';

    return (
        <Box sx={{ width: "100%" }}>
            {/* Top Section */}
            <Box
                sx={{
                    backgroundColor: "#FFD700",
                    textAlign: "center",
                    py: 4,
                    borderRadius: '10px',
                    width: { xs: '90%', sm: '80%', md: '60%' },
                    mx: "auto",
                    position: "relative",
                    bottom: 0,
                    boxShadow: 3,
                }}
            >
                <Typography variant="inherit" sx={{ mb: 2 }}>
                    Nu ezita să apelezi <b>{phone}</b>. Suntem alături de toate călătoriile tale.
                </Typography>
                <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2, fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" }, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    Sună acum:
                    {'\u00A0'}
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 2 }}>
                        <img src={needATaxi} alt="24/7 Taxi Service" style={{ width: '200%', maxWidth: '150px', height: 'auto' }} />
                    </Box>
                </Typography>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#000",
                                color: "#fff",
                                px: 3,
                                py: 1.5,
                                fontSize: { xs: "0.75rem", sm: "1rem" },
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.15)',
                                    backgroundColor: 'red',
                                    border: '1px solid gray',
                                }
                            }}
                            startIcon={<LocalPhoneIcon />}
                            onClick={phoneAction}
                        >
                            {phone}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#000",
                                color: "#fff",
                                px: 3,
                                py: 1.5,
                                fontSize: { xs: "0.75rem", sm: "1rem" },
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.15)',
                                    backgroundColor: `${themeColors.whatsapp}`
                                }
                            }}
                            startIcon={<WhatsAppIcon />}
                            onClick={whatsappAction}
                        >
                            WhatsApp
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            {/* Bottom Section */}
            <Box
                sx={{
                    backgroundColor: "#333",
                    color: "#fff",
                    py: 7,
                    mt: 4,
                    padding: { xs: "20px", sm: "30px", md: "50px 100px" }, // Adjust padding as needed
                }}
            >
                <Grid container spacing={4} justifyContent="center">
                    {/* Company Links */}
                    <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                        <Typography variant="h6" component="h4" sx={{ mb: 2 }}>
                            Locația
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}>
                            <Map />
                        </Box>
                    </Grid>

                    {/* Contact Info */}
                    <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                        <Typography variant="h6" component="h4" sx={{ mb: 2 }}>
                            Contact
                        </Typography>
                        <Typography variant="body1">Mobil:</Typography>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            <li><Typography variant="body2">{phone}</Typography></li>
                        </ul>
                        <br />
                        <Typography variant="body1" component="h4">Email:</Typography>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            <li><Typography variant="body2" >{email1}</Typography></li>
                        </ul>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ContactInfo;
