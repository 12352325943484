
export const Urls = {
  isAdmin: "/admin",
  saveAdmin: "/saveAdmin",
  getVisitors: "/visitors",
  addAVisitor: "/visitor",
  putVisitor: "/visitor",
};
export const baseUrl = process.env.BASE_URL || 'https://botosanitaxi.ro/api';
// export const baseUrl = process.env.BASE_URL || 'http://localhost:5000/api';
