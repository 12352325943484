import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { clientInfo, whatsappToolTipInfo } from '../utils/companyData';
import themeColors from '../utils/themeColors';

/**
 * Renders an instant call component that appears at the bottom left of the screen.
 *
 * @param {Object} props - The props object containing the children and window properties.
 * @param {ReactNode} props.children - The content to be displayed inside the component.
 * @return {JSX.Element} The rendered instant call component.
 */
function WhatsAppToolTip(props) {
  const { whatsappAction } = clientInfo;
  const { children } = props;
  const [isShaking, setIsShaking] = React.useState(false);

  React.useEffect(() => {
    const shakeInterval = setInterval(() => {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500); // Duration of shake
    }, 3000); // Interval between shakes

    return () => clearInterval(shakeInterval);
  }, []);
  return (
    <Box
      onClick={whatsappAction}
      role="presentation"
      sx={{
        position: 'fixed',
        bottom: 70,
        right: 90,
        cursor: 'pointer',
        zIndex: 1300,
        display: 'flex',
        alignItems: 'center',
        transform: isShaking ? 'translateX(5px)' : 'translateX(0)',
        transition: 'transform 0.1s ease-in-out',
      }}
    >
      <Box
        sx={{
          backgroundColor: themeColors.light,
          borderRadius: '10px',
          padding: '15px',
          position: 'relative',
          boxShadow: '8px 8px 12px rgba(0, 0, 0, 0.2), 3px 3px 6px rgba(0, 0, 0, 0.15)',
          border: `1px solid ${themeColors.lightDark}`,
          '&::after': {
            content: '""',
            position: 'absolute',
            right: '-15px',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 0,
            height: 0,
            borderTop: '10px solid transparent',
            borderBottom: '10px solid transparent',
            borderLeft: `10px solid ${themeColors.light}`,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

/**
 * A React component that renders a "Back to Top" button with a scaling effect.
 *
 * @param {object} props - The properties passed to the component.
 * @return {JSX.Element} A JSX element representing the "Back to Top" button.
 */
export default function WhatsAppToolTipModal(props) {
  return (
    <React.Fragment>
      <WhatsAppToolTip {...props}>
        <Box sx={{ color: themeColors.dark, fontSize: '15px', maxWidth: '300px' }}>
          {whatsappToolTipInfo.message}
        </Box>
      </WhatsAppToolTip>
    </React.Fragment>
  );
}

WhatsAppToolTip.propTypes = {
  children: PropTypes.node.isRequired,
};
