import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Pagination,
  PaginationItem,
} from '@mui/material';
import IpTable from './IpTable';
import { AppContext } from '../context/appContext';
import { apiGet } from '../api/api';
import { Urls, baseUrl } from '../api/urls';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import DetailedModal from './DetailedModal';

/**
 * A functional component that renders a responsive admin modal.
 * It fetches a list of visitors from the server and displays them in a table.
 * The modal also includes a refresh button to update the visitor list.
 * The component paginates the list of visitors, displaying a maximum of 10 rows per page.
 * Pagination buttons are displayed at the bottom of the modal.
 *
 * @return {JSX.Element} The rendered admin modal component
 */
const AdminModal = () => {
  const { isAdmin, setIsAdmin } = useContext(AppContext);
  const [visitors, setVisitors] = useState([]);
  const [visitor, setVisitor] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => setIsAdmin(false);

  useEffect(() => {
    isAdmin && getVisitors();
  }, [isAdmin]);

  /**
   * Retrieves a list of visitors from the server and updates the component's state.
   *
   * @return {void}
   */
  const getVisitors = async () => {
    const response = await apiGet(
      `${baseUrl + Urls.getVisitors}?page=${page}&rowsPerPage=${rowsPerPage}`,
      localStorage.getItem('token')
    );
    setVisitors(response.visitors);
  };

  const setSelectedVisitor = (visitorId) => {

    if (visitorId !== null) {
      setVisitor(visitors.find((visitor) => visitor.id === visitorId));
      setShowDetails(true);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    getVisitors();
  };

  return (
    <div>
      <Modal
        open={isAdmin}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle(isMobile)}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: 'center', fontSize: isMobile ? '1.1rem' : '1.25rem' }}
          >
            Bine ai venit pe pagina de administrare!
          </Typography>
          <IpTable
            visitors={visitors.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
            setSelectedVisitor={setSelectedVisitor}
            setVisitors={setVisitors}
          />
          <br />
          <Button
            variant="outlined"
            color="success"
            sx={{ width: '100%', height: '100%', fontSize: isMobile ? '0.8rem' : '0.875rem' }}
            startIcon={<ReplayCircleFilledIcon />}
            onClick={getVisitors}
          >
            Refresh
          </Button>
          <br />
          <Pagination
            count={Math.ceil(visitors.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {visitors.length > 0 && (
              <PaginationItem
                component="div"
                onClick={() => setPage(1)}
                disabled={page === 1}
              >
                First
              </PaginationItem>
            )}
            <PaginationItem
              component="div"
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              Previous
            </PaginationItem>
            <PaginationItem
              component="div"
              onClick={() => setPage(page + 1)}
              disabled={page === Math.ceil(visitors.length / rowsPerPage)}
            >
              Next
            </PaginationItem>
            {visitors.length > 0 && (
              <PaginationItem
                component="div"
                onClick={() =>
                  setPage(Math.ceil(visitors.length / rowsPerPage))
                }
                disabled={page === Math.ceil(visitors.length / rowsPerPage)}
              >
                Last
              </PaginationItem>
            )}
          </Pagination>
          <br />

        </Box>
      </Modal>
      <DetailedModal visitor={visitor} showDetails={showDetails} setShowDetails={setShowDetails} />
    </div>
  );
};

const modalStyle = (isMobile) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 'auto',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: isMobile ? 2 : 4,
  borderRadius: 3,
  maxWidth: isMobile ? '100%' : 'auto',
  overflowY: 'auto',
  maxHeight: '90vh',
});

export default AdminModal;
