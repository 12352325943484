// Define a color theme object
const themeColors = {
  primary: '#3498db',
  secondary: '#2ecc71',
  success: '#28a745',
  danger: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8',
  light: '#f8f9fa',
  lightDark: '#e9ecef',
  dark: '#343a40',
  muted: '#6c757d',
  white: '#ffffff',
  black: '#000000',
  whatsapp: '#22b90f',
  whatsappDark: '#1f9d0d',
  phone: '#de0000',
  // Add more colors as needed
};

// Export the color theme object
export default themeColors;