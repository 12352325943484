import React from 'react';
import styles from './styles.module.css';
export default function Logo() {
  const imageSource = window.location.origin + '/images/mainlogo.svg';
  return (
    <div >
      <img className={`${styles.logoPosition} ${styles.logoEffect}`} src={imageSource} alt='logo' />
    </div>
  );
}
