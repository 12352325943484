import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { apiGet, apiPut } from '../api/api';
import { Urls, baseUrl } from '../api/urls';
/**
 * IpTable
 *
 * @description
 * A table component that displays a list of IPs, the number of visits for each IP, and a button to view more details for each IP.
 *
 * @param {object} visitors - the list of IPs and their corresponding visits
 * @param {function} setSelectedVisitor - a callback function to set the selected visitor
 *
 * @returns {ReactElement} - a table component
 */
export default function IpTable({ visitors, setSelectedVisitor, setVisitors }) {
  const handleUpdateBlockedStatus = async (id, blocked) => {
    // Implement logic to update the blocked status of the selected visitor
    try {
      await apiPut(`${baseUrl + Urls.putVisitor}/${id}`, { blocked });
      const response = await apiGet(
        `${baseUrl + Urls.getVisitors}`,
      );
      setVisitors(response.visitors);
    }
    catch (error) {
      console.error('Error updating blocked status:', error);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ maxWidth: 1000, margin: 'auto', marginTop: '20px' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
            <TableCell sx={{ fontWeight: 'bold' }}>Adresă Ip</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">Vizitări</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">Data</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">Blocat</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">Acţiune</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitors.map((row, index) => (
            <TableRow
              key={row._id}
              sx={{
                backgroundColor: index % 2 === 0 ? '#e5e5e5' : '#cccccc',
                '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell component="th" scope="row" sx={{ padding: '10px' }}>
                {row.ip}
              </TableCell>
              <TableCell align="right" sx={{ padding: '10px' }}>{row.visits}</TableCell>
              <TableCell align="right" sx={{ padding: '10px' }}>
                {new Intl.DateTimeFormat('ro-RO', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(row.date))}
              </TableCell>
              <TableCell align="right" sx={{ padding: '10px' }}>
                <Button variant="contained" size="small" color={row.blocked ? 'error' : 'success'} onClick={() => handleUpdateBlockedStatus(row.id, !row.blocked)} >
                  {row.blocked ? 'Blocat' : 'Deblocat'}

                </Button>
              </TableCell>

              <TableCell align="right" sx={{ padding: '1px' }}>
                <Button size="small" color='inherit' startIcon={<ArrowOutwardIcon />} onClick={() => setSelectedVisitor(row.id)} />
              </TableCell>
            </TableRow>

          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
