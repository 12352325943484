import axios from 'axios';


axios.defaults.baseURL = 'https://botosanitaxi.ro/api';
// axios.defaults.baseURL = 'http://localhost:5000/api';

export const apiGet = async (url, token) => {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  const { data: responses } = await axios.get(url, { headers });
  return responses;
};

export const apiPost = async (url, payload) => {
  const { data: responses } = await axios.post(url, payload);
  return responses;
};

export const apiPut = async (url, payload) => {
  const { data: responses } = await axios.put(url, payload);
  return responses;
};
