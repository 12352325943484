import React, { useContext, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Typography, Box, TextField, Button } from "@mui/material";
import { AppContext } from '../context/appContext';
import { apiPost } from '../api/api';
import { Urls, baseUrl } from '../api/urls';

export default function LoginModal() {
    const { isLoginOpen, showLoginModal, setIsAdmin } = useContext(AppContext);
    const [logData, setLogData] = useState({ email: "", password: "" });

    const handleLogIn = async () => {
        if (logData.email !== "" && logData.password !== "") {
            try {
                const response = await apiPost(baseUrl + Urls.isAdmin, logData);
                if (response.code && response.code === 200) {
                    showLoginModal();
                    setIsAdmin(true);
                    localStorage.setItem("token", response.token);
                }
            }
            catch (error) {
                console.log(error);
                alert("Eroare la autentificare");
            }
        } else {
            alert("Vă rugăm să completați atât email-ul cât și parola.");
        }
        setLogData({ email: "", password: "" });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLogData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <Drawer anchor="right" open={isLoginOpen} onClose={showLoginModal}>
            <Box
                sx={{
                    width: 300,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6" sx={{ mb: 3 }}>
                    Logare
                </Typography>
                <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={logData.email}
                    onChange={handleChange}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label="Parolă"
                    type="password"
                    name="password"
                    value={logData.password}
                    onChange={handleChange}
                    sx={{ mb: 2 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleLogIn}
                >
                    Login
                </Button>
            </Box>
        </Drawer>
    );
}
